import React from 'react';
import { PageProps } from 'gatsby';
import { Layout } from 'components/shared';
import { PrivacyPolicyLayout } from 'components/Policy';
import { DOMAIN_MAP, PRIVACY_POLICY_TYPE } from 'components/Policy/constants';

const PrivacyPolicy: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <PrivacyPolicyLayout
        domain={DOMAIN_MAP.uk}
        policyType={PRIVACY_POLICY_TYPE.website}
      />
    </Layout>
  );
};

export default PrivacyPolicy;
